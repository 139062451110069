import { ABOUT_US_PAGE_LINK, AUTOMATIONS_PAGE_LINK, BLOG_PAGE_LINK, CAREERS_PAGE_LINK, CONTACT_SALES_FROM_FOOTER_PAGE_LINK, CONTACT_US_PAGE_LINK, CRM_MINI_SITE_LINK, DASHBOARDS_PAGE_LINK, ENTERPRISE_PAGE_LINK, FORMS_PAGE_LINK, GANTT_PAGE_LINK, HR_USE_CASES_LINK, INTEGRATIONS_PAGE_LINK, INVESTOR_RELATIONS_LINK, KANBAN_PAGE_LINK, MONDAY_SPACES_PAGE_LINK, NGO_PAGE_LINK, OPERATIONS_CLUSTER_LINK, PARTNERS_PAGE_LINK, PRICING_PAGE_LINK, SMB_PAGE_LINK, SOFTWARE_MINI_SITE_LINK, WHATS_NEW_PAGE_LINK, WORK_MANAGEMENT_FOR_MARKETING_MINI_SITE_LINK, WORK_MANAGEMENT_FOR_PMO_MINI_SITE_LINK, WORK_MANAGEMENT_MINI_SITE_LINK, WORKDOCS_PAGE_LINK, SERVICE_MINI_SITE_LINK, AI_PAGE_LINK } from "/constants/links";
import WM_LOGOS from "constants/work-managment-logos";
export const TITLE_TYPE = {
    MAIN: "MAIN",
    MAIN_CLICKABLE: "MAIN_CLICKABLE",
    SECONDARY: "SECONDARY",
    LINK: "LINK"
};
const PRODUCTS_LINKS = [
    {
        title: "header.products",
        type: TITLE_TYPE.MAIN
    },
    {
        title: "header.tabs.solutions.byProduct.workManagement",
        url: WORK_MANAGEMENT_MINI_SITE_LINK,
        icon: WM_LOGOS.WM_FOOTER_LOGO,
        type: TITLE_TYPE.LINK
    },
    {
        title: "header.tabs.products.mondaySalesCRM.newLayout.topic",
        url: CRM_MINI_SITE_LINK,
        icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/crm_icon_footer.png",
        type: TITLE_TYPE.LINK
    },
    {
        title: "header.tabs.products.mondayDev.newLayout.topic",
        url: SOFTWARE_MINI_SITE_LINK,
        icon: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/footer/dev_new_mobile_footer_logo.png",
        type: TITLE_TYPE.LINK
    },
    {
        title: "header.tabs.products.mondayService.newLayout.topic",
        url: SERVICE_MINI_SITE_LINK,
        icon: "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/uploads/Yotam_Ron/service/Service-logo-footer.png",
        type: TITLE_TYPE.LINK,
        hasBetaTag: true
    }
];
const FEATURES_LINKS = [
    {
        title: "footer.features",
        type: TITLE_TYPE.MAIN
    },
    {
        title: "footer.dashboard",
        url: DASHBOARDS_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.integrations",
        url: INTEGRATIONS_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.automations",
        url: AUTOMATIONS_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "header.tabs.product.gantt.title",
        url: GANTT_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.kanban",
        url: KANBAN_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.workdocs",
        url: WORKDOCS_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.ai",
        url: AI_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "header.tabs.features.keyFeatures.forms.title",
        url: FORMS_PAGE_LINK,
        type: TITLE_TYPE.LINK
    }
];
const COMPANY_LINKS = [
    {
        title: "footer.ourTeam",
        type: TITLE_TYPE.MAIN
    },
    {
        title: "footer.aboutUs",
        url: ABOUT_US_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.careers",
        url: CAREERS_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.investorRelations",
        url: INVESTOR_RELATIONS_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.partners",
        url: PARTNERS_PAGE_LINK,
        type: TITLE_TYPE.LINK
    }
];
const RESOURCES_LINKS = [
    {
        title: "footer.resources",
        type: TITLE_TYPE.MAIN
    },
    {
        title: "footer.blog",
        url: BLOG_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.whatsNew",
        url: WHATS_NEW_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.mondaySpaces",
        url: MONDAY_SPACES_PAGE_LINK,
        type: TITLE_TYPE.LINK
    }
];
const TEAM_TYPE_LINKS = [
    {
        title: "footer.projectManagement",
        url: WORK_MANAGEMENT_FOR_PMO_MINI_SITE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.marketing",
        url: WORK_MANAGEMENT_FOR_MARKETING_MINI_SITE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.salesUseCase",
        url: CRM_MINI_SITE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "header.tabs.solutions.byTeam.developers.title",
        url: SOFTWARE_MINI_SITE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.operations",
        url: OPERATIONS_CLUSTER_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.itOperations",
        url: SERVICE_MINI_SITE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "footer.hr",
        url: HR_USE_CASES_LINK,
        type: TITLE_TYPE.LINK
    }
];
const COMPANY_SIZE_LINKS = [
    {
        title: "header.tabs.solutions.byTeam.companySize",
        type: TITLE_TYPE.MAIN
    },
    {
        title: "footer.enterprise",
        url: ENTERPRISE_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "header.tabs.workflows.byBusinessSize.smallBusiness",
        url: SMB_PAGE_LINK,
        type: TITLE_TYPE.LINK
    },
    {
        title: "header.tabs.workflows.byBusinessSize.nonprofit",
        url: NGO_PAGE_LINK,
        type: TITLE_TYPE.LINK
    }
];
const TEAMS_LINKS = [
    {
        title: "header.teams",
        type: TITLE_TYPE.MAIN
    },
    ...TEAM_TYPE_LINKS,
    ...COMPANY_SIZE_LINKS
];
export const LINKS_DATA_COLUMNS = [
    ...PRODUCTS_LINKS,
    ...FEATURES_LINKS,
    ...COMPANY_LINKS,
    ...RESOURCES_LINKS,
    ...TEAMS_LINKS,
    {
        title: "footer.pricing",
        url: PRICING_PAGE_LINK,
        type: TITLE_TYPE.MAIN_CLICKABLE
    },
    {
        title: "footer.contactSales",
        isClickable: true,
        url: CONTACT_SALES_FROM_FOOTER_PAGE_LINK,
        type: TITLE_TYPE.MAIN_CLICKABLE
    },
    {
        title: "footer.contactUs",
        isClickable: true,
        url: CONTACT_US_PAGE_LINK,
        type: TITLE_TYPE.MAIN_CLICKABLE
    }
];
